:global(#root) {
  .SectionHeader {
    padding: 0;
    background: #F4F4F2;

    .grid {
      .row {
        padding: 0;

        .TextColumn {
          padding: 50px 60px;
          font-family: 'Lora', sans-serif;
          font-size: 32px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            padding: 1rem;
            max-width: 600px;
            line-height: 1.2;
            flex-grow: 0;
          }
        }

        .ImageColumn {
          min-height: 500px;

          background: {
            size: cover;
            position: center;
          }
        }

      }
    }

    @media screen and (max-width: 768px) {
      .grid {
        .row {
          .TextColumn {
            font-size: 28px;

            p {
              text-align: left;
              padding: 2.5rem 1rem;
            }
          }

          .ImageColumn {
            padding: 0!important;
            min-height: 250px;
          }
        }
      }
    }
  }
}
