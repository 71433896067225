.CategoriesList {
  width: 100%;

  .cards {
    justify-content: center;

    &:after {
      display: none;
    }
  }
}
