:global(#root) {
  .ProductsWrapper {
    min-height: 380px;

    &.notFound {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Raleway', sans-serif;
    }
  }

  .ProductsCategoriesHeader {
    background: #F5F6F4;

    a {
      font-family: 'Raleway', sans-serif;
      color: black;
      width: 100%;
      text-align: center;
      display: inline-block;
      padding: 10px 0;
      text-transform: lowercase;

      &:hover:not(.active) {
        text-shadow: 1px 0 0 currentColor;
      }

      &.active {
        font-weight: 700;
        cursor: default;
      }
    }

    @media screen and (max-width: 768px) {
      .column {
        display: flex;
        align-items: center;
        a {
          padding: 5px 0;
          font-size: 12px;
        }
      }
    }
  }
}
