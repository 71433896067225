:global(#root) {
  .ContactForm {

    &.loading {
      &:before {
        background: rgba(246, 247, 245, 0.77);
      }
    }

    .field {
      position: relative;

      input, textarea {
        background: none;
        font-family: 'Raleway', sans-serif;
        font-size: 16px;
        color: #594943;
        padding-left: 0;
        resize: none;
        border: {
          top: 0;
          left: 0;
          right: 0;
          bottom: 2px solid #B3AAA2;
          radius: 0;
        }

        &:focus, &:not(:placeholder-shown) {
          ~label {
            transform: scale(.75) translateY(-20px);
          }
        }
      }

      label {
        font-family: 'Raleway', sans-serif;
        pointer-events: none;
        font-size: 16px;
        font-weight: 400;
        color: #594943;
        position: absolute;
        transition: transform .25s ease-out;
        padding: .67857143em 1em;
        padding-left: 0;
        top: 0;
        left: 0;
        transform-origin: left top;
      }

      .prompt {
        border-radius: 0;
        position: absolute;
        right: 0;

        &:before {
          display: none;
        }

        ~input {
          border-color: indianred;
          color: indianred;
        }
      }
    }

    .button {
      font-family: 'Raleway', sans-serif;
      background: #ABA199;
      color: white;
      text-transform: uppercase;
      border-radius: 0;
      font-size: 18px;
      transition: .25s ease-out;

      &:hover {
        background: darken(#ABA199, 10%);
      }
    }

    .message {
      box-shadow: none;
      border-bottom: 2px solid red;
      border-radius: 0;

      &.positive {
        border-color: darkseagreen;
      }

      &.negative {
        border-color: indianred;
      }
    }
  }
}
