.CategoriesWrapper, .PostsWrapper {
  min-height: 460px;
  position: relative;
}

.CategoriesWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 50px;

  > a {
    font-family: 'Raleway', sans-serif;
    margin-top: auto;
    color: black;
    font-size: 16px;
    border-bottom: 2px solid #E2E0DB;
  }
}

.PostsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 50px;
}

@media screen and (max-width: 768px) {
  .CategoriesWrapper, .PostsWrapper {
    padding: 20px 0;
  }
}
