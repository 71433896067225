:global(#root) {
  .BlogPostWrapper {
    position: relative;
    min-height: 400px;
  }

  .BlogPost {
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
    padding: 50px;
    max-width: 900px;
    margin: 0 auto 75px auto;
    position: relative;

    p {
      line-height: 1.8;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 250px;
      height: 2px;
      left: 50%;
      transform: translateX(-50%);
      background: #E2E0DB;
    }
  }
}
