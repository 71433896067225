:global(#root) {
  .Menu {
    padding: 20px 60px;

    .Logo {
      margin-right: 30px;
    }

    .dropdown {
      transition: .25s ease-out;
      border-radius: 2px;

      .text {
        font-family: 'Raleway', sans-serif;
        color: black;
        font-size: 16px;
        transition: text-shadow .1s ease-in-out;
      }

      &:hover, &.active {
        .text {
          text-shadow: 1px 0 0 currentColor;
        }
      }

      .menu {
        border-radius: 2px;
        box-shadow: none;

        .item {
          padding: 0!important;

          &:hover {
            background: none !important;

            a {
              text-shadow: 1px 0 0 currentColor;
            }
          }

          a {
            display: block;
            width: 100%;
            text-transform: lowercase;
            height: 100%;
            padding: .78571429em 1.14285714em !important;
          }
        }
      }
    }


    .item {
      a {
        font-family: 'Raleway', sans-serif;
        color: black;
        font-size: 16px;
        transition: text-shadow .1s ease-in-out;
      }

      &.active, &:hover {
        background: none;

        > a {
          text-shadow: 1px 0 0 currentColor;
        }
      }
    }

    .SearchForm {
      .search {
        margin-right: 20px;

        input {
          border: none;
          position: relative;
          opacity: 0;
          transition: opacity .25s ease-in-out;
          pointer-events: none;
          font-family: 'Raleway', sans-serif;
          padding: 12px 20px;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          width: calc(100% - 25px);
          height: 2px;
          left: 50%;
          transform: translateX(-50%);
          background: black;
          opacity: 0;
          transition: opacity .25s ease-in-out, transform .25s ease-in-out;
        }
      }

      .searchIcon {
        transition: opacity .25s ease-in-out, transform .25s ease-in-out;
        cursor: pointer;
      }

      .closeSearchIcon {
        opacity: 0;
        pointer-events: none;
        transition: opacity .25s ease-in-out, transform .25s ease-in-out;
      }

      &.visible {
        .closeSearchIcon {
          opacity: 1;
          pointer-events: all;
        }

        .search {
          input, &:after {
            opacity: 1;
            pointer-events: all;
          }
        }
      }
    }

    .CartIcon-Wrapper {
      position: relative;
      margin-top: -10px;
      margin-left: 30px;
      width: 25px;
      text-align: center;

      a {
        font-size: 24px;
        display: inline-block;
        text-align: center;
        width: 100%;
      }

      &:after {
        content: '';
        left: -5px;
        right: -5px;
        transition: left .25s ease-out, right .25s ease-out;
        height: 2px;
        bottom: -10px;
        position: absolute;
        background: black;
      }

      &:hover {
        a {
          text-shadow: none;
        }

        &:after {
          left: 35%;
          right: 35%;
        }
      }

      .CartIcon {
        position: absolute;
        top: 50%;
        left: -40px;
        transform: translateY(-50%);
      }
    }

    .MobileNavWrapper {
      display: none;
    }

    @media screen and (min-width: 768px) {
      >.menu {
        display: flex !important;
      }
    }

    @media screen and (max-width: 768px) {
      padding: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 10;
      background: transparent;

      .MobileNavWrapper {
        height: 80px;
        display: block;
        top: 0;
        position: absolute;
        width: 100%;
        background: white;
      }

      > .menu {
        position: absolute;
        top: 80px;
        margin: 0;

        .menu {
          flex-direction: column;
        }
      }

      .dropdown {
        display: flex;
        flex-direction: column;
        transition: height .25s ease-out;
        align-items: flex-start;

        .menu {
          display: block;
          position: relative;
          height: 0;
          opacity: 0;
          background: transparent;
          border: none;
          max-height: calc(100vh - 80px);
          overflow: auto;
          pointer-events: none;

          .item {
            &:after {
              display: none;
            }
            a {
              padding: 10px 0!important;
              color: #ABA199;
              background: transparent;
            }
          }
        }

        i {
          position: absolute;
          right: 30px;
        }

        &.active {
          .menu {
            height: auto;
            opacity: 1;
            pointer-events: all;
          }
        }
      }

      .item:not(.LogoWrapper):not(.CartIcon-Wrapper) {
        background-color: #F4F4F2;
        margin: 0;
        padding: 30px 35px;

        a, .text {
          font-size: 20px;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 35px;
          width: calc(100% - 70px);
          height: 2px;
          background: #ABA199;
        }

        &:nth-last-of-type(2) {
          &:after {
            display: none;
          }
        }
      }

      .LogoWrapper.mobile {
        position: absolute;
        width: auto!important;
        left: 50%;
        top: 0;
        transform: translateX(-50%) scale(.7);
      }

      .LogoWrapper:not(.mobile), .CartIcon-Wrapper:not(.mobile) {
        display: none;
      }

      .CartIcon-Wrapper.mobile {
        position: absolute;
        right: 25px;
        top: 35px;
        width: 10px !important;
      }

      .SearchForm {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        width: 100%;

        i {
          margin-right: 0;
        }

        .search {
          margin-right: 0;

          input {
            background: transparent;
            padding: 15px 5px;
            margin-top: -10px;
          }

          &:after {
            display: none;
          }
        }
      }
    }
  }
}
