:global(#root) {
  .ContactSegment {
    padding: 60px;
    background: #F6F7F5;
    margin-top: 0;


    .ContactDetails {
      display: flex;
      color: #594943;
      flex-direction: column;
      text-align: right;

      h1 {
        font-family: 'Lora', Tahoma, sans-serif;
        font-weight: 400;
      }

      .list {
        margin-top: auto;

        .item {
          padding: 20px 0;

          a {
            color: #594943;
            font-family: 'Raleway', sans-serif;
            font-size: 16px;
            transition: .25s ease-out;

            .facebook {
              margin-right: 15px;
            }

            .icon {
              font-size: 32px;
            }
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      padding: 30px 20px;

      .column {}
    }
  }
}
