:global(#root) {
  .SingleProductList {
    width: 100%;
    margin: 20px 0;

    .cards {
      justify-content: center;

      &:after {
        display: none;
      }
    }
  }

  .SingleProductList {
    box-shadow: none;
    max-width: 350px;
    position: relative;


    .SingleProductListImage {
      position: relative;
      min-height: 250px;
      background: {
        size: cover;
        repeat: no-repeat;
        position: center;
      }
    }

    p {
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      position: relative;
      z-index: 1;
      font-size: 18px;
      margin: 10px 0;
      font-weight: 700;
      text-align: center;
      font-family: 'Raleway', sans-serif;
      text-transform: lowercase;
      color: black;
      line-height: 1.2;
    }

    .Price {
      font-family: 'Raleway', sans-serif;
      position: relative;
      left: 50%;
      display: inline-block;
      width: 100%;
      text-align: center;
      transform: translateX(-50%);
      font-size: 16px;
      color: black;
    }

    &:hover {
    }

    @media screen and (max-width: 768px) {
      .SingleProductListImage {
        min-height: 150px;
      }
    }
  }
}
