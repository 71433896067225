:global(#root) {
  .CheckoutForm {
    font-family: 'Raleway', sans-serif;

    .header {
      font-family: 'Raleway', sans-serif;
    }

    &.loading {
      &:before {
        background: rgba(246, 247, 245, 0.77);
      }
    }

    .CheckoutInputs {
      .field {
        position: relative;

        input, textarea {
          background: none;
          font-family: 'Raleway', sans-serif;
          font-size: 16px;
          color: #594943;
          padding-left: 0;
          resize: none;
          border: {
            top: 0;
            left: 0;
            right: 0;
            bottom: 2px solid #B3AAA2;
            radius: 0;
          }

          &:focus, &:not(:placeholder-shown) {
            ~label {
              transform: scale(.75) translateY(-20px);
            }
          }
        }

        label {
          font-family: 'Raleway', sans-serif;
          pointer-events: none;
          font-size: 16px;
          font-weight: 400;
          color: #594943;
          position: absolute;
          transition: transform .25s ease-out;
          padding: .67857143em 1em;
          padding-left: 0;
          top: 0;
          left: 0;
          transform-origin: left top;
        }

        .prompt {
          border-radius: 0;

          &:before {
            left: 25px;
          }

          ~input {
            border-color: indianred;
            color: indianred;
          }
        }
      }
    }

    .CheckoutRadios {
      .field {
        margin-bottom: 0;

        &:first-of-type {
          margin-top: -10px;
        }
      }

      label {
        input {
          cursor: pointer;
          height: 0;
          width: 0;
          visibility: hidden;
          padding: 0;
          margin: 0;
          display: block;

          &:checked {
            ~ .label {
              background-color: #E2E0DB;
            }
          }
        }

        .label {
          width: 100%;
          cursor: pointer;
          font-weight: 300;
          font-size: 14px;
          padding: 15px 20px;
          border-radius: 0;
          background-color: #F5F6F4;
          margin-bottom: 0;
          transition: .25s ease-out;

          &:hover {
            background-color: #f1f1f1;
          }

          span {
            float: right;

            p {
              display: inline-block;
              margin: 0;
              padding: 0;
              line-height: 1;
            }

            span {
              margin-left: 5px;
            }

            &.FreeDelivery {
              p {
                text-decoration: line-through red;
              }
            }
          }
        }
      }
    }

    .message {
      box-shadow: none;
      border-bottom: 2px solid red;
      border-radius: 0;

      &.positive {
        border-color: darkseagreen;
      }

      &.negative {
        border-color: indianred;
      }
    }

    .divider {
      margin: 3rem 0;
    }

    &.loading {
      &:before {
        background: rgba(white, 0.8);
        height: 95%;
      }
    }
  }

  .SuccessWrapper {
    position: relative;
    height: 100%;

    .Travolta {
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      position: absolute;
    }
  }

  .CheckoutTotalPrice {
    background: #F5F6F4;
    border: none;
    border-radius: 0;
    font-size: 14px;
    text-align: center;
    font-weight: 700;
  }

  .CheckoutButton {
    font-size: 14px;
    border-radius: 0;
    text-transform: uppercase;
    background-color: #ABA199;
    color: white;
    margin-bottom: 25px;

    &:hover {
      background-color: darken(#ABA199, 3%);
    }
  }

  .CheckoutTextarea, .CheckoutVoucherCode {
    margin-top: 10px;
    border: none;
    border-radius: 0;
    background: #F5F6F4;
    resize: none;
    font-family: 'Raleway', sans-serif;
    height: calc(100% - 48px);

    &.CheckoutVoucherCode {
      font-size: 18px;
      text-transform: uppercase;
      letter-spacing: 2px;
      margin-top: 0;
      rows: 1;
    }
  }

  .CheckoutVoucherButton {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 0;
  }

  .CheckoutTncCheckbox {
    label {
      &:before {
        background-color: #F5F6F4 !important;
      }
    }

    input {
      &:checked {
        ~label {
          &:before {
            background-color: #ABA199 !important
          }
        }
      }
    }
  }

  .CheckoutInputs, .CheckoutRadios, .CheckoutAdditional {
    padding-top: 35px;
  }
  @media screen and (max-width: 768px) {
    .CheckoutInputs, .CheckoutRadios, .CheckoutAdditional {
      padding-top: 35px;
    }
  }
}
