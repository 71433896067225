:global(#root) {
  .VariationsTable {
    text-transform: lowercase;
    font-family: Raleway, sans-serif;
    color: #594943;
    border: none;
    margin-bottom: 0;

    td, tr, tbody, table {
      border: none;
    }

    td {
      padding-left: 0;
      display: inline-flex;
    }
  }
  .VariationsColorWrapper {
    flex-direction: row;
    justify-content: flex-end;
    max-width: 75%;
    flex-wrap: wrap;
    float: right;

    .VariationsColor {
      width: 24px;
      height: 24px;
      margin-bottom: 10px;
      margin-left: 20px;
      cursor: pointer;
      transition: .25s ease-in-out;
      border-radius: 50%;
      position: relative;
      box-shadow: 2px 1px 15px -7px rgba(0,0,0,.75);

      &:hover:not(.active) {
        transform: scale(.85);
      }

      &:after {
        opacity: 0;
        transition: .25s ease-in-out;
        pointer-events: none;
        position: absolute;
        left: -5px;
        top: -5px;
        height: calc(100% + 10px);
        width: calc(100% + 10px);
        border: 1px solid rgba(0, 0, 0, .25);
        content: '';
        border-radius: 50%;

      }

      &.active {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .VariationsTable {
      td {
        font-weight: 300;
        font-size: 16px;
      }

      .table {
        margin: .5em 0;
      }
    }
  }
}
