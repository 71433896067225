:global(#root) {
  .CategoryCard {
    box-shadow: none;
    max-width: 350px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #F7F8F6;
      opacity: .6;
      visibility: visible;
      pointer-events: none;
      transition: .15s ease-out;
    }

    p {
      position: absolute;
      bottom: 25px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      font-size: 24px;
      font-weight: 400;
      text-align: center;
      font-family: 'Raleway', sans-serif;
      color: #594943;
    }

    &:hover {
      &:after {
        opacity: .25;
      }
    }
  }
}
