:global(#root){
  .Footer {
    font-family: 'Raleway', sans-serif;
    margin-top: 0;
    margin-bottom: 0;
    background: #E2E0DB;
    padding: 80px 60px;

    .FooterText {
      font-size: 16px;
      line-height: 28px;

      a {
        color: #594943;
      }
    }

    .list {
      text-align: right;

      .item {
        padding: 10px 0;
        font-size: 16px;

        a {
          color: black;
        }

        &.active, &:hover {
          background: none;

          > a {
            text-shadow: 1px 0 0 currentColor;
          }
        }

        &:last-of-type {
          padding-bottom: 0;
        }
      }
    }

    @media screen and (max-width: 768px) {
      padding: 30px 20px;

      .FooterText {
        line-height: 22px;
        font-size: 14px;
        text-align: center;
        display: inline-block;
        width: 100%;
      }

      .list {
        .item {
          font-size: 14px;
          text-align: center;
        }
      }
    }
  }
}
