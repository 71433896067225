#root .LatestPostsError {
  text-align: center;
  font-family: 'Raleway', sans-serif;
  padding: 10px 15px;
  margin-bottom: 35px;
  font-size: 16px; }

#root .LatestPosts {
  width: 100%;
  margin-bottom: 100px; }
  #root .LatestPosts .cards {
    justify-content: center; }
    #root .LatestPosts .cards:after {
      display: none; }

#root .LatestPostCard {
  box-shadow: none;
  max-width: 300px;
  position: relative; }
  #root .LatestPostCard:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #F7F8F6;
    opacity: .6;
    visibility: visible;
    transition: .25s ease-in-out;
    pointer-events: none; }
  #root .LatestPostCard .LatestPostImage {
    min-height: 300px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
  #root .LatestPostCard p {
    position: absolute;
    width: 100%;
    padding: 20px 50px;
    bottom: 25px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    font-family: 'Lora', sans-serif;
    color: black;
    line-height: 1.2; }
  #root .LatestPostCard .ReadMore {
    font-family: 'Raleway', sans-serif;
    bottom: -50px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 24px;
    color: black; }
    #root .LatestPostCard .ReadMore .arrow {
      margin-left: 10px;
      transition: transform .25s ease-in-out; }
  #root .LatestPostCard:hover .arrow {
    transform: translateX(10px); }
  #root .LatestPostCard:hover:after {
    opacity: .4; }
  @media screen and (max-width: 768px) {
    #root .LatestPostCard {
      max-width: none; } }

#root .LazyImage {
  opacity: 0;
  transition: opacity .15s ease-out;
  max-width: 100%;
  height: auto; }

#root .Loaded {
  opacity: 1; }

#root .LazyDiv {
  width: 100%;
  position: absolute;
  height: 100%;
  opacity: 0;
  transition: opacity .15s ease-out;
  background-size: cover;
  background-position: center; }
  #root .LazyDiv.Loaded {
    opacity: 1; }

#root .CategoryCard {
  box-shadow: none;
  max-width: 350px;
  position: relative; }
  #root .CategoryCard:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #F7F8F6;
    opacity: .6;
    visibility: visible;
    pointer-events: none;
    transition: .15s ease-out; }
  #root .CategoryCard p {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    color: #594943; }
  #root .CategoryCard:hover:after {
    opacity: .25; }

.CategoriesList {
  width: 100%; }
  .CategoriesList .cards {
    justify-content: center; }
    .CategoriesList .cards:after {
      display: none; }

#root .SectionHeader {
  padding: 0;
  background: #F4F4F2; }
  #root .SectionHeader .grid .row {
    padding: 0; }
    #root .SectionHeader .grid .row .TextColumn {
      padding: 50px 60px;
      font-family: 'Lora', sans-serif;
      font-size: 32px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center; }
      #root .SectionHeader .grid .row .TextColumn p {
        padding: 1rem;
        max-width: 600px;
        line-height: 1.2;
        flex-grow: 0; }
    #root .SectionHeader .grid .row .ImageColumn {
      min-height: 500px;
      background-size: cover;
      background-position: center; }
  @media screen and (max-width: 768px) {
    #root .SectionHeader .grid .row .TextColumn {
      font-size: 28px; }
      #root .SectionHeader .grid .row .TextColumn p {
        text-align: left;
        padding: 2.5rem 1rem; }
    #root .SectionHeader .grid .row .ImageColumn {
      padding: 0 !important;
      min-height: 250px; } }

#root .ContactForm.loading:before {
  background: rgba(246, 247, 245, 0.77); }

#root .ContactForm .field {
  position: relative; }
  #root .ContactForm .field input, #root .ContactForm .field textarea {
    background: none;
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    color: #594943;
    padding-left: 0;
    resize: none;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 2px solid #B3AAA2;
    border-radius: 0; }
    #root .ContactForm .field input:not(:-moz-placeholder-shown) ~ label, #root .ContactForm .field textarea:not(:-moz-placeholder-shown) ~ label {
      transform: scale(0.75) translateY(-20px); }
    #root .ContactForm .field input:not(:-ms-input-placeholder) ~ label, #root .ContactForm .field textarea:not(:-ms-input-placeholder) ~ label {
      transform: scale(0.75) translateY(-20px); }
    #root .ContactForm .field input:focus ~ label, #root .ContactForm .field input:not(:placeholder-shown) ~ label, #root .ContactForm .field textarea:focus ~ label, #root .ContactForm .field textarea:not(:placeholder-shown) ~ label {
      transform: scale(0.75) translateY(-20px); }
  #root .ContactForm .field label {
    font-family: 'Raleway', sans-serif;
    pointer-events: none;
    font-size: 16px;
    font-weight: 400;
    color: #594943;
    position: absolute;
    transition: transform .25s ease-out;
    padding: .67857143em 1em;
    padding-left: 0;
    top: 0;
    left: 0;
    transform-origin: left top; }
  #root .ContactForm .field .prompt {
    border-radius: 0;
    position: absolute;
    right: 0; }
    #root .ContactForm .field .prompt:before {
      display: none; }
    #root .ContactForm .field .prompt ~ input {
      border-color: indianred;
      color: indianred; }

#root .ContactForm .button {
  font-family: 'Raleway', sans-serif;
  background: #ABA199;
  color: white;
  text-transform: uppercase;
  border-radius: 0;
  font-size: 18px;
  transition: .25s ease-out; }
  #root .ContactForm .button:hover {
    background: #94877d; }

#root .ContactForm .message {
  box-shadow: none;
  border-bottom: 2px solid red;
  border-radius: 0; }
  #root .ContactForm .message.positive {
    border-color: darkseagreen; }
  #root .ContactForm .message.negative {
    border-color: indianred; }

#root .ContactSegment {
  padding: 60px;
  background: #F6F7F5;
  margin-top: 0; }
  #root .ContactSegment .ContactDetails {
    display: flex;
    color: #594943;
    flex-direction: column;
    text-align: right; }
    #root .ContactSegment .ContactDetails h1 {
      font-family: 'Lora', Tahoma, sans-serif;
      font-weight: 400; }
    #root .ContactSegment .ContactDetails .list {
      margin-top: auto; }
      #root .ContactSegment .ContactDetails .list .item {
        padding: 20px 0; }
        #root .ContactSegment .ContactDetails .list .item a {
          color: #594943;
          font-family: 'Raleway', sans-serif;
          font-size: 16px;
          transition: .25s ease-out; }
          #root .ContactSegment .ContactDetails .list .item a .facebook {
            margin-right: 15px; }
          #root .ContactSegment .ContactDetails .list .item a .icon {
            font-size: 32px; }
  @media screen and (max-width: 768px) {
    #root .ContactSegment {
      padding: 30px 20px; } }

.CategoriesWrapper, .PostsWrapper {
  min-height: 460px;
  position: relative; }

.CategoriesWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 50px; }
  .CategoriesWrapper > a {
    font-family: 'Raleway', sans-serif;
    margin-top: auto;
    color: black;
    font-size: 16px;
    border-bottom: 2px solid #E2E0DB; }

.PostsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 50px; }

@media screen and (max-width: 768px) {
  .CategoriesWrapper, .PostsWrapper {
    padding: 20px 0; } }

#root .SingleProductList {
  width: 100%;
  margin: 20px 0; }
  #root .SingleProductList .cards {
    justify-content: center; }
    #root .SingleProductList .cards:after {
      display: none; }

#root .SingleProductList {
  box-shadow: none;
  max-width: 350px;
  position: relative; }
  #root .SingleProductList .SingleProductListImage {
    position: relative;
    min-height: 250px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
  #root .SingleProductList p {
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    z-index: 1;
    font-size: 18px;
    margin: 10px 0;
    font-weight: 700;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    text-transform: lowercase;
    color: black;
    line-height: 1.2; }
  #root .SingleProductList .Price {
    font-family: 'Raleway', sans-serif;
    position: relative;
    left: 50%;
    display: inline-block;
    width: 100%;
    text-align: center;
    transform: translateX(-50%);
    font-size: 16px;
    color: black; }
  @media screen and (max-width: 768px) {
    #root .SingleProductList .SingleProductListImage {
      min-height: 150px; } }

#root .ProductListWrapper {
  display: flex;
  flex-wrap: wrap; }

#root .ProductsListGrid {
  margin: 0; }

#root .ProductsWrapper {
  min-height: 380px; }
  #root .ProductsWrapper.notFound {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Raleway', sans-serif; }

#root .ProductsCategoriesHeader {
  background: #F5F6F4; }
  #root .ProductsCategoriesHeader a {
    font-family: 'Raleway', sans-serif;
    color: black;
    width: 100%;
    text-align: center;
    display: inline-block;
    padding: 10px 0;
    text-transform: lowercase; }
    #root .ProductsCategoriesHeader a:hover:not(.active) {
      text-shadow: 1px 0 0 currentColor; }
    #root .ProductsCategoriesHeader a.active {
      font-weight: 700;
      cursor: default; }
  @media screen and (max-width: 768px) {
    #root .ProductsCategoriesHeader .column {
      display: flex;
      align-items: center; }
      #root .ProductsCategoriesHeader .column a {
        padding: 5px 0;
        font-size: 12px; } }

#root .CategoryWrapper {
  min-height: 400px; }

#root .VariationsTable {
  text-transform: lowercase;
  font-family: Raleway, sans-serif;
  color: #594943;
  border: none;
  margin-bottom: 0; }
  #root .VariationsTable td, #root .VariationsTable tr, #root .VariationsTable tbody, #root .VariationsTable table {
    border: none; }
  #root .VariationsTable td {
    padding-left: 0;
    display: inline-flex; }

#root .VariationsColorWrapper {
  flex-direction: row;
  justify-content: flex-end;
  max-width: 75%;
  flex-wrap: wrap;
  float: right; }
  #root .VariationsColorWrapper .VariationsColor {
    width: 24px;
    height: 24px;
    margin-bottom: 10px;
    margin-left: 20px;
    cursor: pointer;
    transition: .25s ease-in-out;
    border-radius: 50%;
    position: relative;
    box-shadow: 2px 1px 15px -7px rgba(0, 0, 0, 0.75); }
    #root .VariationsColorWrapper .VariationsColor:hover:not(.active) {
      transform: scale(0.85); }
    #root .VariationsColorWrapper .VariationsColor:after {
      opacity: 0;
      transition: .25s ease-in-out;
      pointer-events: none;
      position: absolute;
      left: -5px;
      top: -5px;
      height: calc(100% + 10px);
      width: calc(100% + 10px);
      border: 1px solid rgba(0, 0, 0, 0.25);
      content: '';
      border-radius: 50%; }
    #root .VariationsColorWrapper .VariationsColor.active:after {
      opacity: 1; }

@media screen and (max-width: 768px) {
  #root .VariationsTable td {
    font-weight: 300;
    font-size: 16px; }
  #root .VariationsTable .table {
    margin: .5em 0; } }

#root .AttributesTable {
  border: none;
  margin-top: 0;
  color: #594943;
  text-transform: lowercase;
  font-family: 'Raleway', sans-serif; }
  #root .AttributesTable tbody, #root .AttributesTable th, #root .AttributesTable tr, #root .AttributesTable td, #root .AttributesTable table {
    border: none; }
  #root .AttributesTable .AttributesCellKey {
    padding-left: 0; }
  #root .AttributesTable tr:first-of-type .AttributesCellValue:hover {
    border-top-color: rgba(34, 36, 38, 0.15); }
  #root .AttributesTable .AttributesCellValue {
    text-align: right;
    transition: .25s ease-out;
    border: 1px solid transparent;
    border-bottom-color: rgba(34, 36, 38, 0.15);
    width: 50%;
    right: 35px;
    position: relative;
    outline-offset: -2px; }
    #root .AttributesTable .AttributesCellValue .text {
      width: 100%;
      text-align: right; }
    #root .AttributesTable .AttributesCellValue .dropdown {
      position: relative;
      box-shadow: none; }
      #root .AttributesTable .AttributesCellValue .dropdown .icon {
        position: absolute;
        right: -40px; }
      #root .AttributesTable .AttributesCellValue .dropdown:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        padding: .78571429em; }
      #root .AttributesTable .AttributesCellValue .dropdown .menu {
        width: calc(100% + .78571429em * 2);
        margin-left: -.78571429em;
        box-shadow: none;
        border-radius: 0;
        opacity: 0;
        margin-top: 10px; }
        #root .AttributesTable .AttributesCellValue .dropdown .menu.visible {
          opacity: 1; }
        #root .AttributesTable .AttributesCellValue .dropdown .menu .item {
          text-align: right;
          transition: background-color .2s ease-out;
          text-transform: lowercase;
          color: #594943; }
          #root .AttributesTable .AttributesCellValue .dropdown .menu .item:hover {
            background-color: #f8f8f7; }
          #root .AttributesTable .AttributesCellValue .dropdown .menu .item.selected {
            background-color: #f5f6f4; }
  #root .AttributesTable .AttributesCellPersonalize {
    padding-left: 0; }
    #root .AttributesTable .AttributesCellPersonalize span {
      color: #594943;
      font-weight: 900;
      font-family: 'Raleway', sans-serif; }

#root .AttributesTextarea {
  margin-top: -10px;
  width: 100%; }
  #root .AttributesTextarea textarea {
    width: 100%;
    resize: none;
    border: 0;
    background-color: #F6F7F5;
    border-radius: 0;
    color: #594943;
    font-family: 'Raleway', sans-serif; }

@media screen and (max-width: 768px) {
  #root .AttributesTable .table {
    margin: .5em 0; }
  #root .AttributesTable .AttributesCellValue {
    border: none; }
  #root .AttributesTable td {
    font-weight: 300;
    font-size: 16px; } }

#root .ProductTitle {
  font-family: 'Lora', sans-serif;
  font-weight: 300;
  color: #594943;
  font-size: 20px;
  padding-top: 25px; }

#root .ProductRail {
  width: 100%; }

#root .ProductImageColumn {
  padding: 0;
  height: 250px; }
  #root .ProductImageColumn:nth-of-type(3n + 1) {
    width: 100% !important;
    height: 500px; }
  #root .ProductImageColumn img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover; }

#root .ProductImageGrid {
  padding-top: 30px; }

#root .ProductDescription {
  padding: 20px 0;
  font-family: 'Raleway', sans-serif;
  color: #594943;
  line-height: 1.63; }
  #root .ProductDescription p {
    line-height: 1.83; }

#root .ProductAddButtonWrapper {
  display: flex;
  flex-grow: 1; }
  #root .ProductAddButtonWrapper .ProductAddButton {
    width: 66.6%;
    text-transform: uppercase;
    font-family: 'Raleway',sans-serif;
    border-radius: 0;
    margin: 20px 0 0 0;
    padding: 20px 15px;
    background-color: #ABA199;
    font-size: 18px;
    color: white; }
  #root .ProductAddButtonWrapper .ProductAddLabel {
    width: 33.4%;
    margin: 20px 0 0 0;
    text-align: center;
    padding: 20px 15px;
    border-radius: 0;
    background-color: #F5F6F4;
    color: #594943;
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
    flex-grow: 1; }

#root .ProductOuterGrid {
  margin-bottom: 30px;
  min-height: 100vh; }

#root .ProductMobileColumn {
  display: none; }

#root .ProductImageSlider {
  display: none; }

#root .ProductImageGrid {
  margin-bottom: 75px; }

#root .ProductSwiper {
  display: none; }

@media screen and (max-width: 768px) {
  #root .ProductSwiper {
    display: block; }
  #root .ProductImageGrid {
    display: none; }
  #root .ProductRail {
    display: none; }
  #root .ProductMobileColumn {
    display: block; }
  #root .swiper-container {
    display: block; }
    #root .swiper-container img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover; } }

#root .CartHeader {
  background: #F5F6F4;
  padding: 20px 0;
  font-family: 'Raleway', sans-serif;
  margin-top: -15px; }

#root .CartGrid {
  width: 100%;
  margin-top: 20px;
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  color: #594943; }
  #root .CartGrid .CartGridHeader {
    font-size: 14px;
    font-weight: 700; }
  #root .CartGrid .CartImage {
    height: 75px;
    width: 75px;
    -o-object-fit: cover;
       object-fit: cover;
    margin-right: 20px; }
  #root .CartGrid .CartName {
    font-weight: 700; }
  #root .CartGrid .CartQuantity {
    margin-right: 10px; }
  #root .CartGrid .CartPrice {
    display: inline-block; }
  #root .CartGrid .CartButton {
    background: transparent;
    padding: 0; }
  #root .CartGrid .CartRemoveButton {
    margin-left: 10px; }
  #root .CartGrid .CartTotalPrice {
    background: #F5F6F4;
    border: none;
    border-radius: 0;
    font-weight: 700; }
  #root .CartGrid .CartCheckoutLink .button {
    border-radius: 0;
    text-transform: uppercase;
    background-color: #ABA199;
    color: white; }
    #root .CartGrid .CartCheckoutLink .button:hover {
      background-color: #a49991; }

@media screen and (max-width: 768px) {
  #root .CartGridHeader {
    display: none; }
  #root .CartGrid .row .aligned {
    text-align: center; }
  #root .CartGrid .row:nth-of-type(2):before {
    display: none; } }

#root .SearchHeader {
  margin-top: 0; }

#root .BlogWrapper {
  min-height: 400px; }

#root .BlogPostWrapper {
  position: relative;
  min-height: 400px; }

#root .BlogPost {
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  padding: 50px;
  max-width: 900px;
  margin: 0 auto 75px auto;
  position: relative; }
  #root .BlogPost p {
    line-height: 1.8; }
  #root .BlogPost:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 250px;
    height: 2px;
    left: 50%;
    transform: translateX(-50%);
    background: #E2E0DB; }


#root .CheckoutForm {
  font-family: 'Raleway', sans-serif; }
  #root .CheckoutForm .header {
    font-family: 'Raleway', sans-serif; }
  #root .CheckoutForm.loading:before {
    background: rgba(246, 247, 245, 0.77); }
  #root .CheckoutForm .CheckoutInputs .field {
    position: relative; }
    #root .CheckoutForm .CheckoutInputs .field input, #root .CheckoutForm .CheckoutInputs .field textarea {
      background: none;
      font-family: 'Raleway', sans-serif;
      font-size: 16px;
      color: #594943;
      padding-left: 0;
      resize: none;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: 2px solid #B3AAA2;
      border-radius: 0; }
      #root .CheckoutForm .CheckoutInputs .field input:not(:-moz-placeholder-shown) ~ label, #root .CheckoutForm .CheckoutInputs .field textarea:not(:-moz-placeholder-shown) ~ label {
        transform: scale(0.75) translateY(-20px); }
      #root .CheckoutForm .CheckoutInputs .field input:not(:-ms-input-placeholder) ~ label, #root .CheckoutForm .CheckoutInputs .field textarea:not(:-ms-input-placeholder) ~ label {
        transform: scale(0.75) translateY(-20px); }
      #root .CheckoutForm .CheckoutInputs .field input:focus ~ label, #root .CheckoutForm .CheckoutInputs .field input:not(:placeholder-shown) ~ label, #root .CheckoutForm .CheckoutInputs .field textarea:focus ~ label, #root .CheckoutForm .CheckoutInputs .field textarea:not(:placeholder-shown) ~ label {
        transform: scale(0.75) translateY(-20px); }
    #root .CheckoutForm .CheckoutInputs .field label {
      font-family: 'Raleway', sans-serif;
      pointer-events: none;
      font-size: 16px;
      font-weight: 400;
      color: #594943;
      position: absolute;
      transition: transform .25s ease-out;
      padding: .67857143em 1em;
      padding-left: 0;
      top: 0;
      left: 0;
      transform-origin: left top; }
    #root .CheckoutForm .CheckoutInputs .field .prompt {
      border-radius: 0; }
      #root .CheckoutForm .CheckoutInputs .field .prompt:before {
        left: 25px; }
      #root .CheckoutForm .CheckoutInputs .field .prompt ~ input {
        border-color: indianred;
        color: indianred; }
  #root .CheckoutForm .CheckoutRadios .field {
    margin-bottom: 0; }
    #root .CheckoutForm .CheckoutRadios .field:first-of-type {
      margin-top: -10px; }
  #root .CheckoutForm .CheckoutRadios label input {
    cursor: pointer;
    height: 0;
    width: 0;
    visibility: hidden;
    padding: 0;
    margin: 0;
    display: block; }
    #root .CheckoutForm .CheckoutRadios label input:checked ~ .label {
      background-color: #E2E0DB; }
  #root .CheckoutForm .CheckoutRadios label .label {
    width: 100%;
    cursor: pointer;
    font-weight: 300;
    font-size: 14px;
    padding: 15px 20px;
    border-radius: 0;
    background-color: #F5F6F4;
    margin-bottom: 0;
    transition: .25s ease-out; }
    #root .CheckoutForm .CheckoutRadios label .label:hover {
      background-color: #f1f1f1; }
    #root .CheckoutForm .CheckoutRadios label .label span {
      float: right; }
      #root .CheckoutForm .CheckoutRadios label .label span p {
        display: inline-block;
        margin: 0;
        padding: 0;
        line-height: 1; }
      #root .CheckoutForm .CheckoutRadios label .label span span {
        margin-left: 5px; }
      #root .CheckoutForm .CheckoutRadios label .label span.FreeDelivery p {
        -webkit-text-decoration: line-through red;
                text-decoration: line-through red; }
  #root .CheckoutForm .message {
    box-shadow: none;
    border-bottom: 2px solid red;
    border-radius: 0; }
    #root .CheckoutForm .message.positive {
      border-color: darkseagreen; }
    #root .CheckoutForm .message.negative {
      border-color: indianred; }
  #root .CheckoutForm .divider {
    margin: 3rem 0; }
  #root .CheckoutForm.loading:before {
    background: rgba(255, 255, 255, 0.8);
    height: 95%; }

#root .SuccessWrapper {
  position: relative;
  height: 100%; }
  #root .SuccessWrapper .Travolta {
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    position: absolute; }

#root .CheckoutTotalPrice {
  background: #F5F6F4;
  border: none;
  border-radius: 0;
  font-size: 14px;
  text-align: center;
  font-weight: 700; }

#root .CheckoutButton {
  font-size: 14px;
  border-radius: 0;
  text-transform: uppercase;
  background-color: #ABA199;
  color: white;
  margin-bottom: 25px; }
  #root .CheckoutButton:hover {
    background-color: #a49991; }

#root .CheckoutTextarea, #root .CheckoutVoucherCode {
  margin-top: 10px;
  border: none;
  border-radius: 0;
  background: #F5F6F4;
  resize: none;
  font-family: 'Raleway', sans-serif;
  height: calc(100% - 48px); }
  #root .CheckoutTextarea.CheckoutVoucherCode, #root .CheckoutVoucherCode.CheckoutVoucherCode {
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 0;
    rows: 1; }

#root .CheckoutVoucherButton {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 0; }

#root .CheckoutTncCheckbox label:before {
  background-color: #F5F6F4 !important; }

#root .CheckoutTncCheckbox input:checked ~ label:before {
  background-color: #ABA199 !important; }

#root .CheckoutInputs, #root .CheckoutRadios, #root .CheckoutAdditional {
  padding-top: 35px; }

@media screen and (max-width: 768px) {
  #root .CheckoutInputs, #root .CheckoutRadios, #root .CheckoutAdditional {
    padding-top: 35px; } }

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.switch-wrapper {
  position: relative;
  height: 100%;

}

.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.App-content {
  flex: 1 1;
  display: inline-grid;
}

.ct-toast-success, .ct-toast-warn {
  font-family: 'Raleway', sans-serif;
}

@media screen and (max-width: 768px) {
  .App-content {
    padding-top: 95px;
  }
}

#root .Menu {
  padding: 20px 60px; }
  #root .Menu .Logo {
    margin-right: 30px; }
  #root .Menu .dropdown {
    transition: .25s ease-out;
    border-radius: 2px; }
    #root .Menu .dropdown .text {
      font-family: 'Raleway', sans-serif;
      color: black;
      font-size: 16px;
      transition: text-shadow .1s ease-in-out; }
    #root .Menu .dropdown:hover .text, #root .Menu .dropdown.active .text {
      text-shadow: 1px 0 0 currentColor; }
    #root .Menu .dropdown .menu {
      border-radius: 2px;
      box-shadow: none; }
      #root .Menu .dropdown .menu .item {
        padding: 0 !important; }
        #root .Menu .dropdown .menu .item:hover {
          background: none !important; }
          #root .Menu .dropdown .menu .item:hover a {
            text-shadow: 1px 0 0 currentColor; }
        #root .Menu .dropdown .menu .item a {
          display: block;
          width: 100%;
          text-transform: lowercase;
          height: 100%;
          padding: .78571429em 1.14285714em !important; }
  #root .Menu .item a {
    font-family: 'Raleway', sans-serif;
    color: black;
    font-size: 16px;
    transition: text-shadow .1s ease-in-out; }
  #root .Menu .item.active, #root .Menu .item:hover {
    background: none; }
    #root .Menu .item.active > a, #root .Menu .item:hover > a {
      text-shadow: 1px 0 0 currentColor; }
  #root .Menu .SearchForm .search {
    margin-right: 20px; }
    #root .Menu .SearchForm .search input {
      border: none;
      position: relative;
      opacity: 0;
      transition: opacity .25s ease-in-out;
      pointer-events: none;
      font-family: 'Raleway', sans-serif;
      padding: 12px 20px; }
    #root .Menu .SearchForm .search:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: calc(100% - 25px);
      height: 2px;
      left: 50%;
      transform: translateX(-50%);
      background: black;
      opacity: 0;
      transition: opacity .25s ease-in-out, transform .25s ease-in-out; }
  #root .Menu .SearchForm .searchIcon {
    transition: opacity .25s ease-in-out, transform .25s ease-in-out;
    cursor: pointer; }
  #root .Menu .SearchForm .closeSearchIcon {
    opacity: 0;
    pointer-events: none;
    transition: opacity .25s ease-in-out, transform .25s ease-in-out; }
  #root .Menu .SearchForm.visible .closeSearchIcon {
    opacity: 1;
    pointer-events: all; }
  #root .Menu .SearchForm.visible .search input, #root .Menu .SearchForm.visible .search:after {
    opacity: 1;
    pointer-events: all; }
  #root .Menu .CartIcon-Wrapper {
    position: relative;
    margin-top: -10px;
    margin-left: 30px;
    width: 25px;
    text-align: center; }
    #root .Menu .CartIcon-Wrapper a {
      font-size: 24px;
      display: inline-block;
      text-align: center;
      width: 100%; }
    #root .Menu .CartIcon-Wrapper:after {
      content: '';
      left: -5px;
      right: -5px;
      transition: left .25s ease-out, right .25s ease-out;
      height: 2px;
      bottom: -10px;
      position: absolute;
      background: black; }
    #root .Menu .CartIcon-Wrapper:hover a {
      text-shadow: none; }
    #root .Menu .CartIcon-Wrapper:hover:after {
      left: 35%;
      right: 35%; }
    #root .Menu .CartIcon-Wrapper .CartIcon {
      position: absolute;
      top: 50%;
      left: -40px;
      transform: translateY(-50%); }
  #root .Menu .MobileNavWrapper {
    display: none; }
  @media screen and (min-width: 768px) {
    #root .Menu > .menu {
      display: flex !important; } }
  @media screen and (max-width: 768px) {
    #root .Menu {
      padding: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 10;
      background: transparent; }
      #root .Menu .MobileNavWrapper {
        height: 80px;
        display: block;
        top: 0;
        position: absolute;
        width: 100%;
        background: white; }
      #root .Menu > .menu {
        position: absolute;
        top: 80px;
        margin: 0; }
        #root .Menu > .menu .menu {
          flex-direction: column; }
      #root .Menu .dropdown {
        display: flex;
        flex-direction: column;
        transition: height .25s ease-out;
        align-items: flex-start; }
        #root .Menu .dropdown .menu {
          display: block;
          position: relative;
          height: 0;
          opacity: 0;
          background: transparent;
          border: none;
          max-height: calc(100vh - 80px);
          overflow: auto;
          pointer-events: none; }
          #root .Menu .dropdown .menu .item:after {
            display: none; }
          #root .Menu .dropdown .menu .item a {
            padding: 10px 0 !important;
            color: #ABA199;
            background: transparent; }
        #root .Menu .dropdown i {
          position: absolute;
          right: 30px; }
        #root .Menu .dropdown.active .menu {
          height: auto;
          opacity: 1;
          pointer-events: all; }
      #root .Menu .item:not(.LogoWrapper):not(.CartIcon-Wrapper) {
        background-color: #F4F4F2;
        margin: 0;
        padding: 30px 35px; }
        #root .Menu .item:not(.LogoWrapper):not(.CartIcon-Wrapper) a, #root .Menu .item:not(.LogoWrapper):not(.CartIcon-Wrapper) .text {
          font-size: 20px; }
        #root .Menu .item:not(.LogoWrapper):not(.CartIcon-Wrapper):after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 35px;
          width: calc(100% - 70px);
          height: 2px;
          background: #ABA199; }
        #root .Menu .item:not(.LogoWrapper):not(.CartIcon-Wrapper):nth-last-of-type(2):after {
          display: none; }
      #root .Menu .LogoWrapper.mobile {
        position: absolute;
        width: auto !important;
        left: 50%;
        top: 0;
        transform: translateX(-50%) scale(0.7); }
      #root .Menu .LogoWrapper:not(.mobile), #root .Menu .CartIcon-Wrapper:not(.mobile) {
        display: none; }
      #root .Menu .CartIcon-Wrapper.mobile {
        position: absolute;
        right: 25px;
        top: 35px;
        width: 10px !important; }
      #root .Menu .SearchForm {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        width: 100%; }
        #root .Menu .SearchForm i {
          margin-right: 0; }
        #root .Menu .SearchForm .search {
          margin-right: 0; }
          #root .Menu .SearchForm .search input {
            background: transparent;
            padding: 15px 5px;
            margin-top: -10px; }
          #root .Menu .SearchForm .search:after {
            display: none; } }

#root .Hamburger {
  width: 32px;
  height: 26px;
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  flex-direction: column; }
  #root .Hamburger .HamburgerLine {
    width: 100%;
    height: 2px;
    background: black;
    transition: .25s ease-out; }
    #root .Hamburger .HamburgerLine:first-of-type {
      transform-origin: right center; }
    #root .Hamburger .HamburgerLine:last-of-type {
      transform-origin: right center; }
  #root .Hamburger.open .HamburgerLine:first-of-type {
    transform: rotate(-45deg); }
  #root .Hamburger.open .HamburgerLine:nth-of-type(2) {
    opacity: 0; }
  #root .Hamburger.open .HamburgerLine:last-of-type {
    transform: rotate(45deg); }

#root .Footer {
  font-family: 'Raleway', sans-serif;
  margin-top: 0;
  margin-bottom: 0;
  background: #E2E0DB;
  padding: 80px 60px; }
  #root .Footer .FooterText {
    font-size: 16px;
    line-height: 28px; }
    #root .Footer .FooterText a {
      color: #594943; }
  #root .Footer .list {
    text-align: right; }
    #root .Footer .list .item {
      padding: 10px 0;
      font-size: 16px; }
      #root .Footer .list .item a {
        color: black; }
      #root .Footer .list .item.active, #root .Footer .list .item:hover {
        background: none; }
        #root .Footer .list .item.active > a, #root .Footer .list .item:hover > a {
          text-shadow: 1px 0 0 currentColor; }
      #root .Footer .list .item:last-of-type {
        padding-bottom: 0; }
  @media screen and (max-width: 768px) {
    #root .Footer {
      padding: 30px 20px; }
      #root .Footer .FooterText {
        line-height: 22px;
        font-size: 14px;
        text-align: center;
        display: inline-block;
        width: 100%; }
      #root .Footer .list .item {
        font-size: 14px;
        text-align: center; } }

