:global(#root) {
  .LazyImage {
    opacity: 0;
    transition: opacity .15s ease-out;
    max-width: 100%;
    height: auto;
  }

  .Loaded {
    opacity: 1;
  }

  .LazyDiv {
    width: 100%;
    position: absolute;
    height: 100%;
    opacity: 0;
    transition: opacity .15s ease-out;
    background: {
      size: cover;
      position: center;
    }

    &.Loaded {
      opacity: 1;
    }
  }
}
