:global(#root) {
  .ProductTitle {
    font-family: 'Lora', sans-serif;
    font-weight: 300;
    color: #594943;
    font-size: 20px;
    padding-top: 25px;
  }
  .ProductRail{
    width: 100%;
  }


  .ProductImageColumn {
    padding: 0;
    height: 250px;

    &:nth-of-type(3n + 1) {
      width: 100%!important;
      height: 500px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .ProductImageGrid {
    padding-top: 30px;
  }

  .ProductDescription {
    padding: 20px 0;
    font-family: 'Raleway', sans-serif;
    color: #594943;
    line-height: 1.63;

    p {
      line-height: 1.83;
    }
  }

  .ProductAddButtonWrapper {
    display: flex;
    flex-grow: 1;

    .ProductAddButton {
      width: 66.6%;
      text-transform: uppercase;
      font-family: 'Raleway',sans-serif;
      border-radius: 0;
      margin: 20px 0 0 0;
      padding: 20px 15px;
      background-color: #ABA199;
      font-size: 18px;
      color: white;
    }

    .ProductAddLabel {
      width: 33.4%;
      margin: 20px 0 0 0;
      text-align: center;
      padding: 20px 15px;
      border-radius: 0;
      background-color: #F5F6F4;
      color: #594943;
      font-family: 'Raleway', sans-serif;
      font-size: 18px;
      flex-grow: 1;
    }
  }

  .ProductOuterGrid {
    margin-bottom: 30px;
    min-height: 100vh;
  }

  .ProductMobileColumn {
    display: none;
  }

  .ProductImageSlider {
    display: none;
  }

  .ProductImageGrid {
    margin-bottom: 75px;
  }

  .ProductSwiper {
    display: none;
  }

  @media screen and (max-width: 768px) {
    .ProductSwiper {
      display: block;
    }

    .ProductImageGrid {
      display: none;
    }

    .ProductRail {
      display: none;
    }

    .ProductMobileColumn {
      display: block;
    }

    .swiper-container {
      display: block;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
