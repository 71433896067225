:global(#root) {
  .AttributesTable {
    border: none;
    margin-top: 0;
    color: #594943;
    text-transform: lowercase;
    font-family: 'Raleway', sans-serif;

    tbody, th, tr, td, table {
      border: none;
    }

    .AttributesCellKey {
      padding-left: 0;
    }

    tr:first-of-type {
      .AttributesCellValue {
        &:hover {
          border-top-color: rgba(34,36,38,.15);

        }
      }
    }

    .AttributesCellValue {
      text-align: right;
      transition: .25s ease-out;
      border: 1px solid transparent;
      border-bottom-color: rgba(34,36,38,.15);
      width: 50%;
      right: 35px;
      position: relative;
      outline-offset: -2px;

      .text {
        width: 100%;
        text-align: right;
      }

      .dropdown {
        position: relative;
        box-shadow: none;

        .icon {
          position: absolute;
          right: -40px;
        }

        &:after {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          padding: .78571429em;
        }

        .menu {
          width: calc(100% + .78571429em * 2);
          margin-left: -.78571429em;
          box-shadow: none;
          border-radius: 0;
          opacity: 0;
          margin-top: 10px;

          &.visible {
            opacity: 1;
          }

          .item {
            text-align: right;
            transition: background-color .2s ease-out;
            text-transform: lowercase;
            color: #594943;

            &:hover {
              background-color: lighten(rgb(245, 246, 244), 1%);
            }

            &.selected {
              background-color: rgb(245, 246, 244);
            }
          }
        }
      }
    }

    .AttributesCellPersonalize {
      padding-left: 0;

      span {
        color: #594943;
        font-weight: 900;
        font-family: 'Raleway', sans-serif;
      }
    }
  }

  .AttributesTextarea {
    margin-top: -10px;
    width: 100%;

    textarea {
      width: 100%;
      resize: none;
      border: 0;
      background-color: #F6F7F5;
      border-radius: 0;
      color: #594943;
      font-family: 'Raleway', sans-serif;
    }
  }

  @media screen and (max-width: 768px) {
    .AttributesTable {
      .table {
        margin: .5em 0;
      }
      .AttributesCellValue {
        border: none;
      }

      td {
        font-weight: 300;
        font-size: 16px;
      }
    }
  }
}
