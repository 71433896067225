body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.switch-wrapper {
  position: relative;
  height: 100%;

}

.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.App-content {
  flex: 1;
  display: inline-grid;
}

.ct-toast-success, .ct-toast-warn {
  font-family: 'Raleway', sans-serif;
}

@media screen and (max-width: 768px) {
  .App-content {
    padding-top: 95px;
  }
}
