:global(#root) {
  .ProductListWrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .ProductsListGrid {
    margin: 0;
  }
}
