:global(#root) {
  .Hamburger {
    width: 32px;
    height: 26px;
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .HamburgerLine {
      width: 100%;
      height: 2px;
      background: black;
      transition: .25s ease-out;

      &:first-of-type {
        transform-origin: right center;
      }

      &:last-of-type {
        transform-origin: right center;
      }
    }

    &.open {
      .HamburgerLine {
        &:first-of-type {
          transform: rotate(-45deg);
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:last-of-type {
          transform: rotate(45deg);
        }
      }
    }
  }
}
