:global(#root) {
  .LatestPostsError {
    text-align: center;
    font-family: 'Raleway', sans-serif;
    padding: 10px 15px;
    margin-bottom: 35px;
    font-size: 16px;
  }

  .LatestPosts {
    width: 100%;
    margin-bottom: 100px;

    .cards {
      justify-content: center;

      &:after {
        display: none;
      }
    }
  }

  .LatestPostCard {
    box-shadow: none;
    max-width: 300px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #F7F8F6;
      opacity: .6;
      visibility: visible;
      transition: .25s ease-in-out;
      pointer-events: none;
    }

    .LatestPostImage {
      min-height: 300px;
      background: {
        size: cover;
        repeat: no-repeat;
        position: center;
      }
    }

    p {
      position: absolute;
      width: 100%;
      padding: 20px 50px;
      bottom: 25px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      font-size: 24px;
      font-weight: 400;
      text-align: center;
      font-family: 'Lora', sans-serif;
      color: black;
      line-height: 1.2;
    }

    .ReadMore {
      font-family: 'Raleway', sans-serif;
      bottom: -50px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      font-size: 24px;
      color: black;

      .arrow {
        margin-left: 10px;
        transition: transform .25s ease-in-out;
      }
    }

    &:hover {
      .arrow {
        transform: translateX(10px);
      }

      &:after {
        opacity: .4;
      }
    }

    @media screen and (max-width: 768px) {
      max-width: none;
    }
  }
}
