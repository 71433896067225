:global(#root) {
  .CartHeader {
    background: #F5F6F4;
    padding: 20px 0;
    font-family: 'Raleway', sans-serif;
    margin-top: -15px;
  }

  .CartGrid {
    width: 100%;
    margin-top: 20px;
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    color: #594943;

    .CartGridHeader {
      font-size: 14px;
      font-weight: 700;
    }

    .CartImage {
      height: 75px;
      width: 75px;
      object-fit: cover;
      margin-right: 20px;
    }

    .CartName {
      font-weight: 700;
    }

    .CartQuantity {
      margin-right: 10px;
    }

    .CartPrice {
      display: inline-block;
    }

    .CartButton {
      background: transparent;
      padding: 0;
    }

    .CartRemoveButton {
      margin-left: 10px;
    }

    .CartTotalPrice {
      background: #F5F6F4;
      border: none;
      border-radius: 0;
      font-weight: 700;
    }

    .CartCheckoutLink {
      .button {
        border-radius: 0;
        text-transform: uppercase;
        background-color: #ABA199;
        color: white;

        &:hover {
          background-color: darken(#ABA199, 3%);
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .CartGridHeader {
      display: none;
    }

    .CartGrid {
      .row {
        .aligned {
          text-align: center;
        }

        &:nth-of-type(2) {
          &:before {
            display: none;
          }
        }
      }
    }
  }
}
